import * as React from 'react'
import { useContext } from 'react'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import TextField from '@mui/material/TextField'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import Link from '@mui/material/Link'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import { useNavigate } from 'react-router-dom'
import { loginUser } from '../../api/auth'
import AppContext from '../../Context'
import { useEffect } from 'react'
import { Layout } from '../../components'
import { COLORS } from '../../constants'

export default function Login () {
  const navigate = useNavigate()
  const { _getDashboard, setUser } = useContext(AppContext)
  useEffect(() => {
    const token = localStorage.getItem('token')
    if (token) {
      const user = localStorage.getItem('user')
      setUser(JSON.parse(user))
      navigate('/dashboard')
      return
    }
  }, [])
  const handleSubmit = async event => {
    event.preventDefault()
    try {
      const data = new FormData(event.currentTarget)
      const payload = {
        email: data.get('email'),
        password: data.get('password')
      }
      const res = await loginUser(payload)
      if (res?.data?.user?.is_admin) {
        localStorage.setItem('token', res?.data?.token)
        localStorage.setItem('user', JSON.stringify(res?.data?.user))
        setUser(res?.data?.user)
        _getDashboard()
        navigate('/dashboard')
      } else {
        alert('Please use admin credentials!')
      }
    } catch (error) {
      const errorText = Object.values(error?.response?.data)
      alert(`Error: ${errorText[0]}`)
    }
  }

  return (
    <Layout>
      <Container component='main' maxWidth='xs'>
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <Typography component='h1' variant='h5' className='font-weight-bold text_primary'>
            Log in
          </Typography>
          <Box
            component='form'
            onSubmit={handleSubmit}
            noValidate
            sx={{
              mt: 1,
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <TextField
              margin='normal'
              required
              fullWidth
              id='email'
              name='email'
              variant='standard'
              autoComplete='email'
              inputProps={{
                className: 'textfield'
              }}
              InputProps={{
                disableUnderline: true
              }}
              className='textfieldContainer'
              autoFocus
            />
            <Grid container alignItems={'center'}>
              <TextField
                margin='normal'
                required
                variant='standard'
                name='password'
                type='password'
                id='password'
                inputProps={{
                  className: 'textfield'
                }}
                InputProps={{
                  disableUnderline: true
                }}
                className='textfieldContainer'
                autoComplete='current-password'
              />
              <Link href='#' variant='body2' className='forgotpass'>
                Forgot password?
              </Link>
            </Grid>
            <Button
              type='submit'
              fullWidth
              variant='contained'
              sx={{
                mt: 3,
                mb: 2,
                backgroundColor: COLORS.secondary,
                borderRadius: 30,
                width: 150
              }}
            >
              Log in
            </Button>
          </Box>
        </Box>
      </Container>
    </Layout>
  )
}
