import * as React from 'react'
import {
  Grid,
  Container,
  Dialog,
  IconButton,
  Button,
  CircularProgress,
  TextField
} from '@mui/material'
import { Layout } from '../../components'
import { ReactComponent as Caret } from '../../assets/svg/Caret.svg'
import AppContext from '../../Context'
import { useContext } from 'react'
import { useState } from 'react'
import { ReactComponent as Feedback } from '../../assets/svg/feedback.svg'
import { ReactComponent as Cross } from '../../assets/svg/cross.svg'
import { COLORS } from '../../constants'
import { respondFeedback } from '../../api/admin'

function FeedBackContent () {
  const { feedbacks, _getFeedbacks } = useContext(AppContext)
  const [state, setState] = useState({
    filteredList: feedbacks,
    feedbackData: null,
    loadingSend: false,
    reply: ''
  })
  const { feedbackData, loadingSend, reply } = state
  const handleChange = (name, value) => {
    setState(pre => ({ ...pre, [name]: value }))
  }

  const filtered = value => {
    if (value) {
      const re = new RegExp(value, 'i')
      var filtered = feedbacks?.filter(entry =>
        Object.values(entry?.user).some(
          val => typeof val === 'string' && val.match(re)
        )
      )
      handleChange('filteredList', filtered)
    } else {
      handleChange('filteredList', feedbacks)
    }
  }

  const handleSubmit = async () => {
    try {
      const token = localStorage.getItem('token')
      handleChange('loadingSend', true)
      const payload = {
        reply,
        feedback_id: feedbackData?.id
      }
      await respondFeedback(payload, token)
      handleChange('loadingSend', false)
      handleChange('feedbackData', null)
      handleChange('reply', '')
      _getFeedbacks()
    } catch (error) {
      handleChange('loadingSend', false)
      const errorText = Object.values(error?.response?.data)
      alert(`Error: ${errorText[0]}`)
    }
  }

  return (
    <Layout>
      <Container maxWidth='lg' sx={{ mt: 4, mb: 4 }}>
        <div className='heading'>
          Feedback
          <Feedback className='ml-3' />
        </div>
        {feedbacks?.map((feedback, index) => (
          <div key={index} className='d-flex align-items-center'>
            <img src={feedback?.user?.picture} className='feedbackUserImage' />
            <div style={{ color: COLORS.secondary, fontWeight: 'bold' }}>
              Name{' '}
              <span style={{ color: COLORS.darkBlue }}>
                {feedback?.user?.name}
              </span>
            </div>
            <div
              className='ml-5 d-flex align-items-center c-pointer'
              onClick={() => handleChange('feedbackData', feedback)}
            >
              <div className='border-left' />
              <div className='text_bold text_primary'>Details</div>
              <Caret className='ml-4' />
            </div>
          </div>
        ))}
      </Container>
      <Dialog
        PaperProps={{ classes: { root: 'paper' } }}
        onClose={() => handleChange('feedbackData', null)}
        open={feedbackData !== null}
      >
        <div className={'zipModal'}>
          <div className='d-flex justify-content-center'>
            <img
              src={feedbackData?.user?.picture}
              className='feedbackModalImage'
            />
          </div>
          <div className='d-flex justify-content-end'>
            <IconButton onClick={() => handleChange('feedbackData', null)}>
              <Cross />
            </IconButton>
          </div>
          <div className='d-flex align-items-center'>
            <p className='modalTextName'>Name</p>
            <p className='modalTextDes'>{feedbackData?.user?.name}</p>
          </div>
          <div className='d-flex align-items-center'>
            <p className='modalTextName'>Email</p>
            <p className='modalTextDes'>{feedbackData?.user?.email}</p>
          </div>
          <div className='d-flex align-items-center'>
            <p className='modalTextName'>Subject</p>
            <p className='modalTextDes'>{feedbackData?.title}</p>
          </div>
          <p className='modalTextName1'>Feedback message</p>
          <p className='modalTextDes'>{feedbackData?.message}</p>

          <p className='modalTextName1 mb-0'>Reply</p>
          <TextField
            margin='normal'
            required
            fullWidth
            id='email'
            multiline
            onChange={value => handleChange('reply', value.target.value)}
            name='email'
            variant='standard'
            autoComplete='email'
            inputProps={{
              className: 'textfieldMulti'
            }}
            InputProps={{
              disableUnderline: true
            }}
            className='textfieldContainer mb-5'
            autoFocus
          />
          <div className='d-flex justify-content-center mt-5'>
            <Button
              variant='contained'
              sx={{
                height: 40,
                textTransform: 'capitalize',
                backgroundColor: COLORS.secondary,
                borderRadius: 30,
                marginRight: 1,
                width: 70
              }}
              onClick={handleSubmit}
            >
              {loadingSend ? <CircularProgress className='loading' /> : 'Send'}
            </Button>
          </div>
        </div>
      </Dialog>
    </Layout>
  )
}

export default function FeedBack () {
  return <FeedBackContent />
}
