import * as React from 'react'
import {
  Grid,
  Container,
  TextField,
  Button,
  Dialog,
  IconButton,
  CircularProgress
} from '@mui/material'
import { Layout } from '../../components'
import AppTable from '../../components/AppTable'
import { useContext } from 'react'
import AppContext from '../../Context'
import { useState } from 'react'
import {
  banUser,
  banUserReports,
  deleteReportProduct,
  deleteUser,
  reportUser,
  sendWarning,
  unbanUserReports,
  updateUser
} from '../../api/admin'
import { useEffect } from 'react'
import { ReactComponent as FlaggedContentIcon } from '../../assets/svg/flaggedcontent.svg'
import { ReactComponent as Cross } from '../../assets/svg/cross.svg'
import { COLORS } from '../../constants'

const headCells1 = [
  {
    id: 'username',
    numeric: false,
    disablePadding: true,
    label: 'User'
  },
  {
    id: 'content',
    numeric: false,
    center: true,
    disablePadding: false,
    label: 'Content'
  },
  {
    id: 'ContentUser',
    numeric: false,
    center: true,
    disablePadding: false,
    label: 'Content Posted by'
  },
  {
    id: 'num_of_reports',
    numeric: false,
    center: true,
    disablePadding: false,
    label: 'User reported'
  },
  {
    id: 'reportedBy',
    numeric: false,
    center: true,
    disablePadding: false,
    label: 'Reported by'
  },
  {
    id: 'comment',
    numeric: false,
    center: true,
    disablePadding: false,
    label: 'Comment'
  }
]
function FlaggedContent () {
  const { reports, _getReports } = useContext(AppContext)
  const [state, setState] = useState({
    filteredList: reports,
    reportID: '',
    product_id: '',
    message: '',
    loadingSend: false,
    loadingDelete: false,
    loadingBan: false,
    ban_from: '',
    ban_date: ''
  })
  const {
    filteredList,
    loadingSend,
    loadingDelete,
    product_id,
    loadingBan,
    reportID,
    message,
    ban_from,
    ban_date,
    loadingUnban
  } = state
  const handleChange = (name, value) => {
    setState(pre => ({ ...pre, [name]: value }))
  }

  useEffect(() => {
    if (reports) {
      handleChange('filteredList', reports)
    }
  }, [reports])

  const filtered = value => {
    if (value) {
      const re = new RegExp(value, 'i')
      var filtered = reports?.filter(entry =>
        Object.values(entry).some(
          val => typeof val === 'string' && val.match(re)
        )
      )
      handleChange('filteredList', filtered)
    } else {
      handleChange('filteredList', reports)
    }
  }

  const _updateUser = async (id, flag, is_active) => {
    try {
      const token = localStorage.getItem('token')
      const payload = {
        flag
      }
      if (is_active) {
        payload.is_active = true
        delete payload.flag
      }
      await updateUser(id, payload, token)
      _getReports()
    } catch (error) {
      const errorText = Object.values(error?.response?.data)
      alert(`Error: ${errorText[0]}`)
    }
  }

  const _sendWarning = async () => {
    try {
      if (reportID) {
        handleChange('loadingSend', true)
        const token = localStorage.getItem('token')
        const payload = {
          report: reportID,
          message
        }
        await sendWarning(payload, token)
        handleChange('loadingSend', false)
        handleChange('reportID', '')
        handleChange('message', '')
        _getReports()
      }
    } catch (error) {
      handleChange('loadingSend', false)
      const errorText = Object.values(error?.response?.data)
      alert(`Error: ${errorText[0]}`)
    }
  }

  const deleteAction = id => {
    handleChange('userID', id)
  }

  const _deleteContent = async () => {
    try {
      if (product_id) {
        handleChange('loadingDelete', true)
        const token = localStorage.getItem('token')
        const payload = {
          product_id
        }
        await deleteReportProduct(payload, token)
        handleChange('loadingDelete', false)
        handleChange('product_id', '')
        _getReports()
      }
    } catch (error) {
      handleChange('loadingDelete', false)
      const errorText = Object.values(error?.response?.data)
      alert(`Error: ${errorText[0]}`)
    }
  }

  const _banUser = async id => {
    try {
      handleChange('loadingBan', true)
      const token = localStorage.getItem('token')
      const payload = {
        ban_from: ban_from?.includes('post')
          ? 'post'
          : ban_from?.includes('app')
          ? 'app'
          : 'both',
        user_id: id
      }
      ban_from?.includes('both') && ban_date && (payload.ban_date = ban_date)
      await banUserReports(payload, token)
      _getReports()
      alert('User has been banned')
      handleChange('loadingBan', false)
    } catch (error) {
      handleChange('loadingBan', false)
      const errorText = Object.values(error?.response?.data)
      alert(`Error: ${errorText[0]}`)
    }
  }
  const _unBanUser = async id => {
    try {
      handleChange('loadingUnban', true)
      const token = localStorage.getItem('token')
      const payload = {
        user_id: id
      }
      await unbanUserReports(payload, token)
      _getReports()
      alert('User has been unbanned')
      handleChange('loadingUnban', false)
    } catch (error) {
      handleChange('loadingUnban', false)
      const errorText = Object.values(error?.response?.data)
      alert(`Error: ${errorText[0]}`)
    }
  }
  const _reportUser = async id => {
    try {
      handleChange('loadingDelete', true)
      const token = localStorage.getItem('token')
      const payload = `?user_id=${id}`
      const res = await reportUser(payload, token)
      console.log('res', res?.data)
      _getReports()
    } catch (error) {
      handleChange('loadingDelete', false)
      const errorText = Object.values(error?.response?.data)
      alert(`Error: ${errorText[0]}`)
    }
  }

  const banAction = id => {
    handleChange('banID', id)
  }

  console.log('reports', reports)
  return (
    <Layout>
      <Container maxWidth='lg' sx={{ mt: 4, mb: 4 }}>
        <div className='heading'>
          Flagged Content
          <FlaggedContentIcon className='ml-3' />
        </div>
        <Grid container justifyContent={'center'}>
          <div class='search'>
            <span class='form-element'>
              <span class='fa fa-search'></span>
              <input
                placeholder='Search'
                onChange={value => filtered(value.target.value)}
              />
            </span>
          </div>
        </Grid>
        <Grid container style={{ marginBottom: -50, marginTop: 50 }}>
          <div>
            <FlaggedContentIcon
              style={{ width: 15, height: 15 }}
              className='mr-1'
            />
            Report type
          </div>
        </Grid>

        {reports?.length > 0 && (
          <AppTable
            rows={filteredList}
            flagAction={_updateUser}
            deleteAction={deleteAction}
            banAction={banAction}
            _reportUser={_reportUser}
            handleChange={handleChange}
            handleBan={_banUser}
            handleUnban={_unBanUser}
            loadingBan={loadingBan}
            loadingUnban={loadingUnban}
            flagged
            rowsPage={15}
            ban_from={ban_from}
            ban_date={ban_date}
            // headingLeft={'User activity'}
            headingRight={''}
            headCells={headCells1}
          />
        )}
        <Dialog
          PaperProps={{ classes: { root: 'paper' } }}
          onClose={() => handleChange('reportID', '')}
          open={reportID !== ''}
        >
          <div className={'zipModal'}>
            <div className='d-flex justify-content-end'>
              <IconButton onClick={() => handleChange('reportID', '')}>
                <Cross />
              </IconButton>
            </div>
            <p className='modalText'>Warning</p>
            <TextField
              margin='normal'
              required
              variant='standard'
              name='message'
              type='message'
              id='message'
              multiline
              sx={{ width: '100%', minHeight: 100 }}
              inputProps={{
                style: { minHeight: 100 },
                className: 'textfieldSubs1'
              }}
              InputProps={{
                disableUnderline: true
              }}
              placeholder='Message'
              onChange={value => handleChange('message', value.target.value)}
              className='textfieldContainerSubs1'
              autoComplete='current-description'
            />

            <div className='d-flex justify-content-center mt-4'>
              <Button
                variant='contained'
                sx={{
                  height: 40,
                  textTransform: 'capitalize',
                  backgroundColor: COLORS.secondary,
                  borderRadius: 30,
                  marginRight: 1,
                  width: 150
                }}
                disabled={!message || !reportID}
                onClick={() => _sendWarning()}
              >
                {loadingSend ? (
                  <CircularProgress className='loading' />
                ) : (
                  'Send Warning'
                )}
              </Button>
              <Button
                onClick={() => handleChange('reportID', '')}
                variant='contained'
                sx={{
                  height: 40,
                  textTransform: 'capitalize',
                  backgroundColor: COLORS.secondary,
                  color: COLORS.white,
                  borderRadius: 30,
                  marginRight: 1,
                  width: 80
                }}
              >
                CANCEL
              </Button>
            </div>
          </div>
        </Dialog>
        <Dialog
          PaperProps={{ classes: { root: 'paper' } }}
          onClose={() => handleChange('product_id', '')}
          open={product_id !== ''}
        >
          <div className={'zipModal'}>
            <div className='d-flex justify-content-end'>
              <IconButton onClick={() => handleChange('product_id', '')}>
                <Cross />
              </IconButton>
            </div>
            <p className='modalText'>
              Are you sure you want to delete content?
            </p>
            <div className='d-flex justify-content-center mt-4'>
              <Button
                variant='contained'
                sx={{
                  height: 40,
                  textTransform: 'capitalize',
                  backgroundColor: COLORS.secondary,
                  borderRadius: 30,
                  marginRight: 1,
                  width: 70
                }}
                disabled={!product_id}
                onClick={() => _deleteContent()}
              >
                {loadingDelete ? (
                  <CircularProgress className='loading' />
                ) : (
                  'DELETE'
                )}
              </Button>
              <Button
                onClick={() => handleChange('product_id', '')}
                variant='contained'
                sx={{
                  height: 40,
                  textTransform: 'capitalize',
                  backgroundColor: COLORS.secondary,
                  color: COLORS.white,
                  borderRadius: 30,
                  marginRight: 1,
                  width: 80
                }}
              >
                CANCEL
              </Button>
            </div>
          </div>
        </Dialog>
      </Container>
    </Layout>
  )
}

export default function Flagged () {
  return <FlaggedContent />
}
