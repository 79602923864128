import * as React from 'react'
import { Grid, Container, CircularProgress, Dialog, IconButton, Button } from '@mui/material'
import { Layout } from '../../components'
import { useContext } from 'react'
import { ReactComponent as Analytics } from '../../assets/svg/analytics.svg'
import AppTable from '../../components/AppTable'
import AppContext from '../../Context'
import { COLORS, getError } from '../../constants'
import { ReactComponent as Cross } from '../../assets/svg/cross.svg'
import { deleteAdminMilkRequests, deleteAdminProduct } from '../../api/admin'

function DashboardContent() {
  const { adminMilkRequests, adminProduct, _getDashboard } = useContext(AppContext)
  const [state, setState] = React.useState({
    postID: '',
    requestID: '',
    loadingDeletePost: false,
    loadingDeleteRequest: false
  })
  const { postID, loadingDeletePost, loadingDeleteRequest, requestID } = state
  const handleChange = (name, value) => {
    setState(pre => ({ ...pre, [name]: value }))
  }
  const headCells = [
    {
      id: 'title',
      numeric: false,
      disablePadding: true,
      label: 'Product'
    },
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: 'name'
    },
    {
      id: 'email',
      numeric: false,
      center: true,
      disablePadding: false,
      label: 'email'
    },
    {
      id: 'status',
      numeric: false,
      disablePadding: false,
      label: 'status'
    },
    {
      id: 'price',
      numeric: false,
      disablePadding: false,
      label: 'Total'
    },
    {
      id: 'deletePost',
      numeric: false,
      disablePadding: false,
      label: 'Action'
    }
  ]
  const headCells1 = [
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: 'name'
    },
    {
      id: 'email',
      numeric: false,
      center: true,
      disablePadding: false,
      label: 'email'
    },
    {
      id: 'requested_on',
      numeric: false,
      disablePadding: true,
      label: 'Date'
    },
    {
      id: 'status',
      numeric: false,
      disablePadding: false,
      label: 'status'
    },
    {
      id: 'note',
      numeric: false,
      disablePadding: false,
      label: 'Notes'
    },
    {
      id: 'deletePost',
      numeric: false,
      disablePadding: false,
      label: 'Action'
    }
  ]

  const deletePostModal = (id) => {
    handleChange('postID', id)
  }

  const deleteRequestModal = (id) => {
    handleChange('requestID', id)
  }

  const handleDelete = async () => {
    try {
      const token = localStorage.getItem('token')
      handleChange('loadingDeletePost', true)
      await deleteAdminProduct(postID, token)
      handleChange('loadingDeletePost', false)
      handleChange('postID', '')
      _getDashboard()
    } catch (error) {
      handleChange('loadingDeletePost', false)
      getError(error)
    }
  }

  const handleDeleteRequest = async () => {
    try {
      const token = localStorage.getItem('token')
      handleChange('loadingDeleteRequest', true)
      await deleteAdminMilkRequests(requestID, token)
      handleChange('loadingDeleteRequest', false)
      handleChange('requestID', '')
      _getDashboard()
    } catch (error) {
      handleChange('loadingDeleteRequest', false)
      getError(error)
    }
  }

  return (
    <Layout>
      <Container maxWidth='lg' sx={{ mt: 4, mb: 4 }}>
        <div className='heading'>
          Analytics
          <Analytics className='ml-3' />
        </div>
        {adminProduct?.length > 0 && (
          <AppTable
            rows={adminProduct?.length > 0 && adminProduct[0]}
            rowsPage={15}
            handleDelete={deletePostModal}
            isProduct
            noPagination
            headingRight={''}
            headCells={headCells}
          />
        )}
        <Grid container justifyContent={'flex-end'} style={{ width: '85%' }}>
          <div className='totalvalue'>Total Value</div>
          <div className='totalvalueNumber'>
            ${' '}
            {adminProduct?.length > 0 &&
              Number(adminProduct[1]?.price__sum)?.toFixed(2)}
          </div>
        </Grid>
        <Grid container>
          <div className='milkRequestHead'>Milk Request</div>
        </Grid>
        {adminMilkRequests?.length > 0 && (
          <AppTable
            rows={adminMilkRequests}
            handleDelete={deleteRequestModal}
            rowsPage={15}
            isMilk
            noPagination
            headingRight={''}
            headCells={headCells1}
          />
        )}
      </Container>
      <Dialog
        PaperProps={{ classes: { root: 'paper' } }}
        onClose={() => handleChange('postID', '')}
        open={postID !== ''}
      >
        <div className={'zipModal'}>
          <div className='d-flex justify-content-end'>
            <IconButton onClick={() => handleChange('postID', '')}>
              <Cross />
            </IconButton>
          </div>
          <p className='modalText'>
            Are you sure you want to delete this post?{' '}
          </p>

          <div className='d-flex justify-content-center mt-4'>
            <Button
              variant='contained'
              sx={{
                height: 40,
                textTransform: 'capitalize',
                backgroundColor: COLORS.secondary,
                borderRadius: 30,
                marginRight: 1,
                width: 70
              }}
              disabled={loadingDeletePost}
              onClick={handleDelete}
            >
              {loadingDeletePost ? (
                <CircularProgress className='loading' />
              ) : (
                'DELETE'
              )}
            </Button>
            <Button
              onClick={() => handleChange('postID', '')}
              variant='contained'
              sx={{
                height: 40,
                textTransform: 'capitalize',
                backgroundColor: COLORS.secondary,
                color: COLORS.white,
                borderRadius: 30,
                marginRight: 1,
                width: 80
              }}
            >
              CANCEL
            </Button>
          </div>
        </div>
      </Dialog>
      <Dialog
        PaperProps={{ classes: { root: 'paper' } }}
        onClose={() => handleChange('requestID', '')}
        open={requestID !== ''}
      >
        <div className={'zipModal'}>
          <div className='d-flex justify-content-end'>
            <IconButton onClick={() => handleChange('requestID', '')}>
              <Cross />
            </IconButton>
          </div>
          <p className='modalText'>
            Are you sure you want to delete this request?{' '}
          </p>

          <div className='d-flex justify-content-center mt-4'>
            <Button
              variant='contained'
              sx={{
                height: 40,
                textTransform: 'capitalize',
                backgroundColor: COLORS.secondary,
                borderRadius: 30,
                marginRight: 1,
                width: 70
              }}
              disabled={loadingDeleteRequest}
              onClick={handleDeleteRequest}
            >
              {loadingDeleteRequest ? (
                <CircularProgress className='loading' />
              ) : (
                'DELETE'
              )}
            </Button>
            <Button
              onClick={() => handleChange('requestID', '')}
              variant='contained'
              sx={{
                height: 40,
                textTransform: 'capitalize',
                backgroundColor: COLORS.secondary,
                color: COLORS.white,
                borderRadius: 30,
                marginRight: 1,
                width: 80
              }}
            >
              CANCEL
            </Button>
          </div>
        </div>
      </Dialog>
    </Layout>
  )
}

export default function Dashboard() {
  return <DashboardContent />
}
