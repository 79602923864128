import { API } from './'

export const getDashboard = token => {
  return API.get(`api/v1/admin/dashboard/`, token)
}

export const getAllUsers = (payload, token) => {
  return API.get(`api/v1/admin/users/${payload}`, token)
}

export const getZipcodes = (payload, token) => {
  return API.get(`api/v1/admin/zipcodes/${payload}`, token)
}

export const updateZipcodes = (id, payload, token) => {
  return API.patch(`api/v1/admin/zipcodes/${id}/`, payload, token)
}

export const updateUser = (id, payload, token) => {
  return API.patch(`api/v1/admin/users/${id}/`, payload, token)
}

export const deleteZipcodes = (id, token) => {
  return API.delete(`api/v1/admin/zipcodes/${id}/`, {}, token)
}

export const deleteOrder = (id, token) => {
  return API.delete(`api/v1/admin/orders/${id}/`, {}, token)
}

export const deleteUser = (id, token) => {
  return API.delete(`api/v1/admin/users/${id}/`, {}, token)
}

export const banUser = (payload, token) => {
  return API.post(`api/v1/admin/users/ban_user/`, payload, token)
}

export const reportUser = (payload, token) => {
  return API.get(`api/v1/admin/users/report/${payload}`, token)
}

export const addZipcodes = (payload, token) => {
  return API.post(`api/v1/admin/zipcodes/`, payload, token)
}

export const getAdminSubscriptions = token => {
  return API.get(`api/v1/admin/subscriptions/plans/`, token)
}

export const getOrderDetails = (id, token) => {
  return API.get(`api/v1/admin/orders/${id}/`, token)
}

export const getFeedbacks = (payload, token) => {
  return API.get(`api/v1/admin/feedback/${payload}`, token)
}

export const respondFeedback = (payload, token) => {
  return API.post(`api/v1/admin/feedback/respond/`, payload, token)
}

export const createPlan = (payload, token) => {
  return API.post(`api/v1/admin/subscriptions/plans/`, payload, token)
}

export const deletePlan = (payload, token) => {
  return API.delete(`api/v1/admin/subscriptions/plans/`, payload, token)
}

export const getCoupons = token => {
  return API.get(`api/v1/admin/subscriptions/coupons/`, token)
}

export const getAnalytics = token => {
  return API.get(`api/v1/admin/users/analytics/`, token)
}

export const getReports = token => {
  return API.get(`api/v1/admin/reports/`, token)
}

export const banUserReports = (payload, token) => {
  return API.post(`api/v1/admin/reports/ban/`, payload, token)
}

export const sendWarning = (payload, token) => {
  return API.post(`api/v1/admin/reports/warning/`, payload, token)
}

export const unbanUserReports = (payload, token) => {
  return API.delete(`api/v1/admin/reports/ban/`, payload, token)
}

export const deleteReportProduct = (payload, token) => {
  return API.delete(`api/v1/admin/reports/product/`, payload, token)
}

export const deleteReport = (id, token) => {
  return API.delete(`api/v1/admin/reports/${id}/`, {}, token)
}

export const sendCoupons = (payload, token) => {
  return API.post(`api/v1/admin/subscriptions/coupons/`, payload, token)
}

export const getAdminProduct = (payload, token) => {
  return API.get(`api/v1/admin/products/${payload}`, token)
}

export const deleteAdminProduct = (id, token) => {
  return API.delete(`api/v1/admin/products/${id}/`, {}, token)
}

export const getAdminMilkRequests = (payload, token) => {
  return API.get(`api/v1/admin/milk_requests/${payload}`, token)
}

export const deleteAdminMilkRequests = (id, token) => {
  return API.delete(`api/v1/admin/milk_requests/${id}/`, {}, token)
}
