// App Fonts
export const FONT1REGULAR = 'Arsenal-Regular'
export const FONT1BOLD = 'Arsenal-Bold'
export const FONT1BOLDITALIC = 'Arsenal-BoldItalic'
export const FONT1ITALIC = 'Arsenal-Italic'

export const PROFILEICON =
  'https://cdn4.iconfinder.com/data/icons/web-ui-color/128/Account-512.png'

// MAPS
export const GOOGLE_API_KEY = 'AIzaSyC-1dUdU_nJ8N4Zh3ijPzLF7MANu6sIkKQ'

export const mapStyle = [
  {
    featureType: 'poi.business',
    stylers: [
      {
        visibility: 'off'
      }
    ]
  },
  {
    featureType: 'poi.park',
    elementType: 'labels.text',
    stylers: [
      {
        visibility: 'off'
      }
    ]
  }
]


export const getError = error => {
  const errorText = Object.values(error?.response?.data)
  if (errorText?.length > 0) {
    if (
      errorText[0] === "Invalid token" ||
      errorText[0] === "Token has expired" ||
      error?.response?.status === 401
    ) {
      localStorage.removeItem("token")
      localStorage.removeItem("user")
      window.location.reload()
    } else {
      alert(`Error: ${JSON.stringify(errorText[0])}`)
    }
  } else {
    alert(`Error: ${JSON.stringify(error?.message)}`)
  }
}

export const OrderStatus = ['All', 'Pending', 'Accepted', 'Completed']
export const OrderStatus1 = ['Pending', 'Accepted', 'Completed']

// App Colors
export const COLORS = {
  primary: 'rgba(151, 130, 132, 1)',
  primary04: 'rgba(126, 0, 126, 0.4)',
  backgroud: '#F5EDEE',
  secondary: 'rgba(151, 130, 132, 1)',
  listBG: 'rgba(230, 213, 215, 1)',
  white: 'white',
  productBox: '#DDC187',
  darkBlue: '#001F47',
  pumping: '#9AC984',
  sleeping: '#C0969A',
  diaper: '#3A596E',
  notificationBox: '#E6D5D7',
  redDot: '#C5042B',
  lightergrey: '#FBFBFD',
  messageTextRight: 'rgba(205, 171, 174, 1)',
  modalBG: 'rgba(0,0,0,0.6)',
  lightblue: 'rgba(57, 189, 183, 0.1)',
  grey: '#C4C4C4',
  card: '#FBFBFB',
  logout: '#606060',
  activeTab: '#DFBFDF',
  darkGrey: '#6B6B6B',
  darkBlack: '#000',
  darkRed: '#FF0000',
  lightgrey: '#F6F6F6',
  orange: '#FF7D09',
  black: '#231F20',
  navy: 'rgba(58, 89, 110, 1)',
  black06: 'rgba(0,0,0,0.6)',
  greyButton: '#474951',
  inputPrefixBG: '#E4F4EE',
  inputText: '#A3ACB8',
  inputBorder: 'rgba(221, 193, 135, 1)',
  completed: '#458D00',
  imageBGBorder: '#30313A',
  imageText: '#BABABD',
  alertButon: '#FF7D09',
  borderColor: 'rgba(0,0,0,0.25)',
  success: '#83D333',
  heartBG: 'rgba(255,255,255,0.6)',
  placeholder: '#A3ACB8',
  drawerBottomWidth: '#33333308',
  settingsIconBG: 'rgba(51, 51, 51, 0.03)'
}
