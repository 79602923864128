import './App.css'
import { Route, Routes, useNavigate } from 'react-router-dom'
// import { PrivateRoute, Loader } from './components'
import { createTheme, ThemeProvider } from '@mui/material/styles'

//ROUTES
import * as ROUTES from './constants/routes'
import ReactGA from 'react-ga'
//CONTAINERS
import {
  Login,
  Dashboard,
  Users,
  Flagged,
  FeedBack,
  Request,
  RequestDetails,
  Subscription
} from './containers'
import AppContext from './Context'
import {
  getAdminMilkRequests,
  getAdminProduct,
  getAdminSubscriptions,
  getAllUsers,
  getAnalytics,
  getCoupons,
  getDashboard,
  getFeedbacks,
  getOrders,
  getReports,
  getZipcodes
} from './api/admin'
import { useEffect, useState } from 'react'
import { COLORS, getError } from './constants'

const theme = createTheme({
  palette: {
    primary: {
      light: COLORS.primary,
      main: COLORS.primary,
      dark: '#002884',
      contrastText: '#fff'
    },
    secondary: {
      light: COLORS.secondary,
      main: COLORS.secondary,
      dark: '#ba000d',
      contrastText: '#000'
    }
  },
  typography: {
    fontFamily: ['Arsenal'].join(',')
  }
})
function App() {
  const TRACKING_ID = 'UA-244572427-1' // OUR_TRACKING_ID
  ReactGA.initialize(TRACKING_ID)
  const navigate = useNavigate()
  const [user, setUser] = useState(null)
  const [dashboard, setDashboard] = useState(null)
  const [allUsers, setAllUsers] = useState([])
  const [zipcodes, setZipcodes] = useState([])
  const [orders, setOrders] = useState([])
  const [feedbacks, setFeedbacks] = useState([])
  const [adminSubscriptions, setAdminSubscriptions] = useState([])
  const [coupons, setCoupons] = useState([])
  const [analytics, setAnalytics] = useState([])
  const [reports, setReports] = useState([])
  const [adminProduct, setAdminProduct] = useState([])
  const [adminMilkRequests, setAdminMilkRequests] = useState([])

  useEffect(() => {
    _getDashboard()
  }, [])

  const _getDashboard = async () => {
    try {
      const token = localStorage.getItem('token')
      if (!token) {
        navigate('/')
        return
      }
      // const res = await getDashboard(token)
      const resAdminProduct = await getAdminProduct('', token)
      const resAdminMilkRequests = await getAdminMilkRequests('', token)
      // setDashboard(res?.data)
      setAdminProduct(resAdminProduct?.data)
      setAdminMilkRequests(resAdminMilkRequests?.data)
    } catch (error) {
      getError(error)
    }
  }

  const _getAllUsers = async () => {
    try {
      const token = localStorage.getItem('token')
      const res = await getAllUsers('', token)
      setAllUsers(res?.data)
    } catch (error) {
      getError(error)
    }
  }

  const _getZipcodes = async () => {
    try {
      const token = localStorage.getItem('token')
      const res = await getZipcodes('', token)
      setZipcodes(res?.data)
    } catch (error) {
      getError(error)
    }
  }

  // const _getOrders = async () => {
  //   try {
  //     const token = localStorage.getItem('token')
  //     const res = await getOrders('', token)
  //     setOrders(res?.data)
  //   } catch (error) {
  //     const errorText = Object.values(error?.response?.data)
  //     alert(`Error: ${errorText[0]}`)
  //   }
  // }

  const _getFeedbacks = async () => {
    try {
      const token = localStorage.getItem('token')
      const res = await getFeedbacks('?responded=False', token)
      setFeedbacks(res?.data)
    } catch (error) {
      getError(error)
    }
  }

  const _getAdminSubscriptions = async () => {
    try {
      const token = localStorage.getItem('token')
      const res = await getAdminSubscriptions(token)
      setAdminSubscriptions(res?.data?.data)
    } catch (error) {
      getError(error)
    }
  }

  const _getCoupons = async () => {
    try {
      const token = localStorage.getItem('token')
      const res = await getCoupons(token)
      setCoupons(res?.data?.data)
    } catch (error) {
      getError(error)
    }
  }

  const _getAnalytics = async () => {
    try {
      const token = localStorage.getItem('token')
      const res = await getAnalytics(token)
      setAnalytics(res?.data?.reports)
    } catch (error) {
      getError(error)
    }
  }

  const _getReports = async () => {
    try {
      const token = localStorage.getItem('token')
      const res = await getReports(token)
      setReports(res?.data)
    } catch (error) {
      getError(error)
    }
  }

  return (
    <AppContext.Provider
      value={{
        setUser,
        user,
        dashboard,
        _getDashboard,
        _getAllUsers,
        allUsers,
        _getZipcodes,
        zipcodes,
        orders,
        // _getOrders,
        feedbacks,
        _getFeedbacks,
        adminSubscriptions,
        _getAdminSubscriptions,
        coupons,
        _getCoupons,
        _getAnalytics,
        analytics,
        _getReports,
        reports,
        adminProduct,
        adminMilkRequests
      }}
    >
      <ThemeProvider theme={theme}>
        <Routes>
          <Route path={ROUTES.MAIN} element={<Login />} />
          <Route path={ROUTES.DASHBOARD} element={<Dashboard />} />
          <Route path={ROUTES.USER} element={<Users />} />
          <Route path={ROUTES.FLAGGED} element={<Flagged />} />
          <Route path={ROUTES.FEEDBACK} element={<FeedBack />} />
          <Route path={ROUTES.SUBSCRIPTION} element={<Subscription />} />
          {/* <Route path={ROUTES.REQUESTDETAILS} element={<RequestDetails />} /> */}
        </Routes>
      </ThemeProvider>
    </AppContext.Provider>
  )
}

export default App
