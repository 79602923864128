import * as React from 'react'
import {
  Grid,
  Container,
  TextField,
  Button,
  Dialog,
  IconButton,
  CircularProgress
} from '@mui/material'
import { Layout } from '../../components'
import AppTable from '../../components/AppTable'
import { useContext } from 'react'
import AppContext from '../../Context'
import { useState } from 'react'
import { banUser, deleteUser, reportUser, updateUser } from '../../api/admin'
import { useEffect } from 'react'
import { ReactComponent as UserIcon } from '../../assets/svg/users.svg'
import { ReactComponent as Cross } from '../../assets/svg/cross.svg'
import { COLORS } from '../../constants'

const headCells1 = [
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'name'
  },
  {
    id: 'email',
    numeric: false,
    center: true,
    disablePadding: false,
    label: 'email'
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    label: 'status'
  }
]
function UsersContent () {
  const { allUsers, _getAllUsers } = useContext(AppContext)
  const [state, setState] = useState({
    filteredList: allUsers,
    userID: '',
    banID: '',
    loadingDelete: false,
    loadingBan: false
  })
  const { filteredList, loadingDelete, loadingBan, banID, userID } = state
  const handleChange = (name, value) => {
    setState(pre => ({ ...pre, [name]: value }))
  }

  useEffect(() => {
    if (allUsers) {
      handleChange('filteredList', allUsers)
    }
  }, [allUsers])

  const filtered = value => {
    if (value) {
      const re = new RegExp(value, 'i')
      var filtered = allUsers?.filter(entry =>
        Object.values(entry).some(
          val => typeof val === 'string' && val.match(re)
        )
      )
      handleChange('filteredList', filtered)
    } else {
      handleChange('filteredList', allUsers)
    }
  }

  const _updateUser = async (id, flag, is_active) => {
    try {
      const token = localStorage.getItem('token')
      const payload = {
        flag
      }
      if (is_active) {
        payload.is_active = true
        delete payload.flag
      }
      await updateUser(id, payload, token)
      _getAllUsers()
    } catch (error) {
      const errorText = Object.values(error?.response?.data)
      alert(`Error: ${errorText[0]}`)
    }
  }

  const _deleteUser = async () => {
    try {
      if (userID) {
        handleChange('loadingDelete', true)
        const token = localStorage.getItem('token')
        await deleteUser(userID, token)
        handleChange('loadingDelete', false)
        handleChange('userID', '')
        _getAllUsers()
      }
    } catch (error) {
      handleChange('loadingDelete', false)
      const errorText = Object.values(error?.response?.data)
      alert(`Error: ${errorText[0]}`)
    }
  }

  const deleteAction = id => {
    handleChange('userID', id)
  }

  const _banUser = async () => {
    try {
      if (banID) {
        handleChange('loadingBan', true)
        const token = localStorage.getItem('token')
        const payload = {
          user_id: banID
        }
        await banUser(payload, token)
        handleChange('loadingBan', false)
        handleChange('banID', '')
        _getAllUsers()
      }
    } catch (error) {
      handleChange('loadingBan', false)
      const errorText = Object.values(error?.response?.data)
      alert(`Error: ${errorText[0]}`)
    }
  }

  const _reportUser = async id => {
    try {
      handleChange('loadingBan', true)
      const token = localStorage.getItem('token')
      const payload = `?user_id=${id}`
      const res = await reportUser(payload, token)
      console.log('res', res?.data)
      _getAllUsers()
    } catch (error) {
      handleChange('loadingBan', false)
      const errorText = Object.values(error?.response?.data)
      alert(`Error: ${errorText[0]}`)
    }
  }

  const banAction = id => {
    handleChange('banID', id)
  }

  return (
    <Layout>
      <Container maxWidth='lg' sx={{ mt: 4, mb: 4 }}>
        <div className='heading'>
          Users
          <UserIcon className='ml-3' />
        </div>
        <Grid container justifyContent={'center'}>
          <div class='search'>
            <span class='form-element'>
              <span class='fa fa-search'></span>
              <input
                placeholder='Search users'
                onChange={value => filtered(value.target.value)}
              />
            </span>
          </div>
        </Grid>
        {allUsers && (
          <AppTable
            rows={filteredList}
            flagAction={_updateUser}
            deleteAction={deleteAction}
            banAction={banAction}
            _reportUser={_reportUser}
            approval
            rowsPage={15}
            // headingLeft={'User activity'}
            headingRight={''}
            headCells={headCells1}
          />
        )}
        <Dialog
          PaperProps={{ classes: { root: 'paper' } }}
          onClose={() => handleChange('userID', '')}
          open={userID !== ''}
        >
          <div className={'zipModal'}>
            <div className='d-flex justify-content-end'>
              <IconButton onClick={() => handleChange('userID', '')}>
                <Cross />
              </IconButton>
            </div>
            <p className='modalText'>
              Are you sure you want to delete this user?{' '}
            </p>

            <div className='d-flex justify-content-center mt-4'>
              <Button
                variant='contained'
                sx={{
                  height: 40,
                  textTransform: 'capitalize',
                  backgroundColor: COLORS.secondary,
                  borderRadius: 30,
                  marginRight: 1,
                  width: 70
                }}
                onClick={() => _deleteUser()}
              >
                {loadingDelete ? (
                  <CircularProgress className='loading' />
                ) : (
                  'DELETE'
                )}
              </Button>
              <Button
                onClick={() => handleChange('userID', '')}
                variant='contained'
                sx={{
                  height: 40,
                  textTransform: 'capitalize',
                  backgroundColor: COLORS.secondary,
                  color: COLORS.white,
                  borderRadius: 30,
                  marginRight: 1,
                  width: 80
                }}
              >
                CANCEL
              </Button>
            </div>
          </div>
        </Dialog>
        <Dialog
          PaperProps={{ classes: { root: 'paper' } }}
          onClose={() => handleChange('banID', '')}
          open={banID !== ''}
        >
          <div className={'zipModal'}>
            <div className='d-flex justify-content-end'>
              <IconButton onClick={() => handleChange('banID', '')}>
                <Cross />
              </IconButton>
            </div>
            <p className='modalText'>Are you sure you want to ban this user?</p>

            <div className='d-flex justify-content-center mt-4'>
              <Button
                variant='contained'
                sx={{
                  height: 40,
                  textTransform: 'capitalize',
                  backgroundColor: COLORS.secondary,
                  borderRadius: 30,
                  marginRight: 1,
                  width: 70
                }}
                onClick={() => _banUser()}
              >
                {loadingBan ? (
                  <CircularProgress className='loading' />
                ) : (
                  'DELETE'
                )}
              </Button>
              <Button
                onClick={() => handleChange('banID', '')}
                variant='contained'
                sx={{
                  height: 40,
                  textTransform: 'capitalize',
                  backgroundColor: COLORS.secondary,
                  color: COLORS.white,
                  borderRadius: 30,
                  marginRight: 1,
                  width: 80
                }}
              >
                CANCEL
              </Button>
            </div>
          </div>
        </Dialog>
      </Container>
    </Layout>
  )
}

export default function Users () {
  return <UsersContent />
}
