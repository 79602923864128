import * as React from 'react'
import {
  Grid,
  Container,
  Dialog,
  IconButton,
  Button,
  CircularProgress,
  TextField,
  Switch,
  MenuItem,
  Radio
} from '@mui/material'
import { Layout } from '../../components'
import { ReactComponent as Caret } from '../../assets/svg/Caret.svg'
import AppContext from '../../Context'
import { useContext } from 'react'
import { useState } from 'react'
import { ReactComponent as Cross } from '../../assets/svg/cross.svg'
import { ReactComponent as Save } from '../../assets/svg/save.svg'
import { ReactComponent as DeleteUser } from '../../assets/svg/deleteUser.svg'
import { ReactComponent as Send } from '../../assets/svg/send.svg'
import { COLORS } from '../../constants'
import {
  createPlan,
  deletePlan,
  respondFeedback,
  sendCoupons
} from '../../api/admin'
import { ReactComponent as Subscriptions } from '../../assets/svg/subscriptions.svg'
import styled from '@emotion/styled'
import AppTable from '../../components/AppTable'

const IOSSwitch = styled(props => (
  <Switch
    focusVisibleClassName='.Mui-focusVisible'
    inputProps={{ 'aria-label': 'secondary checkbox' }}
    disableRipple
    {...props}
  />
))(({ theme }) => ({
  width: 50,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(24px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#A87C51' : '#9AC984',
        opacity: 1,
        border: 0
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5
      }
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff'
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600]
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3
    }
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    // width:'100%',
    backgroundColor: theme.palette.mode === 'light' ? '#818181' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500
    }),
    '&:after, &:before': {
      color: 'white',
      fontSize: '11px',
      position: 'absolute',
      top: '6px'
    },
    '&:after': {
      content: "'On'",
      left: '8px'
    },
    '&:before': {
      content: "'Off'",
      right: '7px'
    }
  }
}))
function SubscriptionContent () {
  const {
    feedbacks,
    _getAdminSubscriptions,
    _getFeedbacks,
    adminSubscriptions,
    _getCoupons,
    coupons
  } = useContext(AppContext)
  const [state, setState] = useState({
    filteredList: feedbacks,
    feedbackData: null,
    loadingSend: false,
    reply: '',
    active: 0,
    amount: '',
    interval: '',
    description: '',
    sendType: '',
    createType: '',
    selectedPlan: null,
    isNewPlan: false,
    trial_period_days: false,
    amountCoupon: '',
    nameCoupon: '',
    durationCoupon: ''
  })
  const {
    feedbackData,
    loadingSend,
    reply,
    isNewPlan,
    trial_period_days,
    active,
    amount,
    interval,
    description,
    sendType,
    createType,
    selectedPlan,
    filteredList,
    amountCoupon,
    nameCoupon,
    durationCoupon
  } = state
  const handleChange = (name, value) => {
    setState(pre => ({ ...pre, [name]: value }))
  }

  const filtered = value => {
    if (value) {
      const re = new RegExp(value, 'i')
      var filtered = feedbacks?.filter(entry =>
        Object.values(entry?.user).some(
          val => typeof val === 'string' && val.match(re)
        )
      )
      handleChange('filteredList', filtered)
    } else {
      handleChange('filteredList', feedbacks)
    }
  }

  const handleSubmit = async () => {
    try {
      const token = localStorage.getItem('token')
      handleChange('loadingSend', true)
      const payload = {
        reply,
        feedback_id: feedbackData?.id
      }
      await respondFeedback(payload, token)
      handleChange('loadingSend', false)
      handleChange('feedbackData', null)
      handleChange('reply', '')
      _getFeedbacks()
    } catch (error) {
      handleChange('loadingSend', false)
      const errorText = Object.values(error?.response?.data)
      alert(`Error: ${errorText[0]}`)
    }
  }

  const handleCreate = async () => {
    try {
      const token = localStorage.getItem('token')
      handleChange('loadingSend', true)
      const payload = {
        interval,
        trial_days: trial_period_days ? 30 : 0,
        amount,
        description,
        nickname: planTypes.filter(e => e.value === interval)[0].label
      }
      await createPlan(payload, token)
      handleChange('loadingSend', false)
      handleChange('trial_period_days', false)
      handleChange('interval', '')
      handleChange('amount', '')
      handleChange('description', '')
      handleChange('isNewPlan', false)
      _getAdminSubscriptions()
    } catch (error) {
      handleChange('loadingSend', false)
      const errorText = Object.values(error?.response?.data)
      alert(`Error: ${errorText[0]}`)
    }
  }

  const handleSend = async id => {
    try {
      const token = localStorage.getItem('token')
      handleChange('loadingSend', true)
      let payload
      if (id) {
        payload = {
          send: true,
          code: id,
          send_to: sendType
        }
      } else {
        payload = {
          amount: amountCoupon,
          name: nameCoupon,
          duration: durationCoupon
        }
      }
      console.log('payload', payload)
      await sendCoupons(payload, token)
      handleChange('loadingSend', false)
      handleChange('sendType', '')
      handleChange('amountCoupon', '')
      handleChange('nameCoupon', '')
      handleChange('durationCoupon', '')
      _getCoupons()
      alert(!id ? 'Coupon has been created' : 'Code has been sent')
    } catch (error) {
      handleChange('loadingSend', false)
      console.log('error', error)
      const errorText = Object.values(error?.response?.data)
      alert(`Error: ${errorText[0]}`)
    }
  }

  const handleDelete = async plan_id => {
    try {
      const token = localStorage.getItem('token')
      handleChange('loadingSend', true)
      const payload = {
        plan_id
      }
      await deletePlan(payload, token)
      _getAdminSubscriptions()
    } catch (error) {
      handleChange('loadingSend', false)
      const errorText = Object.values(error?.response?.data)
      alert(`Error: ${errorText[0]}`)
    }
  }

  const headingPlan = [
    'Subscription Plan',
    'Package description',
    'Set price',
    'Free trial'
  ]

  const headingCoupons = ['Code', 'Times used', 'Activate', 'Send to']

  const label = { inputProps: { 'aria-label': 'Switch demo' } }

  const planTypes = [
    {
      value: 'day',
      label: 'Daily'
    },
    {
      value: 'week',
      label: 'Weekly'
    },
    {
      value: 'month',
      label: 'Monthly'
    },
    {
      value: 'year',
      label: 'Yearly'
    }
  ]

  const headCells1 = [
    {
      id: 'Date',
      numeric: false,
      disablePadding: true,
      label: 'Date'
    },
    {
      id: 'email',
      numeric: false,
      center: true,
      disablePadding: false,
      label: 'User full name'
    },
    {
      id: 'email',
      numeric: false,
      center: true,
      disablePadding: false,
      label: 'Subscription package'
    },
    {
      id: 'email',
      numeric: false,
      center: true,
      disablePadding: false,
      label: 'Amount'
    },
    {
      id: 'email',
      numeric: false,
      center: true,
      disablePadding: false,
      label: 'Code'
    },
    {
      id: 'email',
      numeric: false,
      center: true,
      disablePadding: false,
      label: 'Total amount earned for chosen period of time'
    },
    {
      id: 'total',
      numeric: false,
      disablePadding: false,
      label: 'Total amount earned for chosen period of time'
    }
  ]

  return (
    <Layout>
      <Container maxWidth='lg' sx={{ mt: 4, mb: 4 }}>
        <div className='heading'>
          Subscriptions
          <Subscriptions className='ml-3' />
        </div>
        <Grid container justifyContent={'space-around'}>
          <div
            onClick={() => handleChange('active', 0)}
            className={active === 0 ? 'tabSubsActive' : 'tabSubs'}
          >
            Subscription Plan
          </div>
          <div
            onClick={() => handleChange('active', 1)}
            className={active === 1 ? 'tabSubsActive' : 'tabSubs'}
          >
            All promo codes generated
          </div>
          <div
            onClick={() => handleChange('active', 2)}
            className={active === 2 ? 'tabSubsActive' : 'tabSubs'}
          >
            Generate promo code
          </div>
          {/* <div
            onClick={() => handleChange('active', 3)}
            className={active === 3 ? 'tabSubsActive' : 'tabSubs'}
          >
            Payments
          </div> */}
        </Grid>
        {active === 0 && (
          <>
            <Grid container>
              {headingPlan.map((plan, index) => (
                <div
                  style={{
                    textAlign: index === 2 ? 'right' : 'left',
                    marginRight: 20,
                    width: index === 2 ? 100 : index === 1 ? 300 : 200
                  }}
                  key={index}
                  className='tabSubsActive'
                >
                  {plan}
                </div>
              ))}
            </Grid>
            {adminSubscriptions?.map((plan, index) => (
              <div key={index} className='d-flex align-items-center mb-2 mt-2'>
                <div style={{ color: COLORS.secondary, width: 200 }}>
                  <span style={{ color: COLORS.darkBlue }}>
                    {plan?.nickname}
                  </span>
                </div>
                <div
                  className='descriptionBox'
                  // onClick={() => handleChange('feedbackData', plan)}
                >
                  <div className=''>{plan?.metadata?.description}</div>
                </div>
                <div
                  style={{
                    color: COLORS.secondary,
                    textAlign: 'right',
                    width: 140
                  }}
                >
                  <span style={{ color: COLORS.darkBlue }}>
                    ${plan?.amount}
                  </span>
                </div>
                <div
                  style={{
                    color: COLORS.secondary,
                    textAlign: 'right',
                    width: 140
                  }}
                >
                  <IOSSwitch
                    {...label}
                    // disabled
                    checked={plan?.trial_period_days}
                    // onClick={() => handleChange('showMap', !showMap)}
                  />
                </div>
                <IconButton onClick={() => handleDelete(plan?.id)}>
                  <DeleteUser />
                </IconButton>
                <IconButton>
                  <Save />
                </IconButton>
              </div>
            ))}
            <div
              style={{
                color: COLORS.secondary,
                fontWeight: 'bold',
                cursor: 'pointer'
              }}
              onClick={() => handleChange('isNewPlan', true)}
            >
              + Add suscription package
            </div>
            {isNewPlan && (
              <>
                <Grid container>
                  {headingPlan.map((plan, index) => (
                    <div
                      style={{
                        textAlign: 'left',
                        marginRight: 20,
                        width: index === 2 ? 100 : index === 1 ? 300 : 200
                      }}
                      key={index}
                      className='tabSubsActive'
                    >
                      {plan}
                    </div>
                  ))}
                </Grid>
                <div className='d-flex align-items-center mb-2 mt-2'>
                  <div style={{ color: COLORS.secondary, width: 200 }}>
                    <TextField
                      margin='normal'
                      select
                      required
                      variant='standard'
                      name='password'
                      type='password'
                      id='password'
                      inputProps={{
                        className: 'textfieldSubs'
                      }}
                      InputProps={{
                        disableUnderline: true
                      }}
                      onChange={value =>
                        handleChange('interval', value.target.value)
                      }
                      className='textfieldContainerSubs'
                      autoComplete='current-password'
                    >
                      {planTypes.map(option => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>
                  <TextField
                    margin='normal'
                    required
                    variant='standard'
                    name='description'
                    type='description'
                    id='description'
                    inputProps={{
                      className: 'textfieldSubs1'
                    }}
                    InputProps={{
                      disableUnderline: true
                    }}
                    placeholder='Description'
                    onChange={value =>
                      handleChange('description', value.target.value)
                    }
                    className='textfieldContainerSubs1'
                    autoComplete='current-description'
                  />
                  <TextField
                    margin='normal'
                    required
                    variant='standard'
                    name='description'
                    type='description'
                    id='description'
                    inputProps={{
                      className: 'textfieldSubs'
                    }}
                    InputProps={{
                      disableUnderline: true
                    }}
                    placeholder='$ 0.00'
                    sx={{ ml: 2 }}
                    onChange={value =>
                      handleChange('amount', value.target.value)
                    }
                    className='textfieldContainerSubs'
                    autoComplete='current-description'
                  />
                  <div
                    style={{
                      color: COLORS.secondary,
                      textAlign: 'right',
                      width: 140
                    }}
                  >
                    <IOSSwitch
                      {...label}
                      // disabled
                      checked={trial_period_days}
                      onClick={() =>
                        handleChange('trial_period_days', !trial_period_days)
                      }
                    />
                  </div>
                  <IconButton
                    disabled={!amount || !interval || !description}
                    onClick={handleCreate}
                  >
                    <Save />
                  </IconButton>
                </div>
              </>
            )}
          </>
        )}
        {active === 1 && (
          <>
            <div className='tabSubsActive mt-4'>All promo codes generated</div>
            <Grid container>
              {headingCoupons.map((coupon, index) => (
                <div
                  style={{
                    textAlign: index === 2 ? 'right' : 'left',
                    marginRight: 20,
                    width: index === 2 ? 100 : index === 1 ? 100 : 200
                  }}
                  key={index}
                  className='tabSubsActive'
                >
                  {coupon}
                </div>
              ))}
            </Grid>
            {coupons?.map((coupon, index) => (
              <div key={index} className='d-flex align-items-center mb-2 mt-2'>
                <div style={{ color: COLORS.secondary, width: 200 }}>
                  <span style={{ color: COLORS.darkBlue }}>{coupon?.id}</span>
                </div>
                <div style={{ color: COLORS.secondary }}>
                  <div className='ml-4'>{coupon?.times_redeemed}</div>
                </div>
                <div
                  style={{
                    color: COLORS.secondary,
                    textAlign: 'right',
                    width: 200
                  }}
                >
                  <IOSSwitch
                    {...label}
                    // disabled
                    checked={coupon?.valid}
                    // onClick={() => handleChange('showMap', !showMap)}
                  />
                </div>
                <div className='d-flex align-items-center'>
                  <Radio
                    checked={sendType === 'Buyers'}
                    onChange={() => handleChange('sendType', 'Buyers')}
                  />
                  <div className='ml-1 mr-2'>Buyers</div>
                </div>
                <div className='d-flex align-items-center'>
                  <Radio
                    checked={sendType === 'Sellers'}
                    onChange={() => handleChange('sendType', 'Sellers')}
                  />
                  <div className='ml-1 mr-2'>Sellers</div>
                </div>
                <div className='d-flex align-items-center'>
                  <Radio
                    checked={sendType === 'All'}
                    onChange={() => handleChange('sendType', 'All')}
                  />
                  <div className='ml-1 mr-2'>All</div>
                </div>
                <IconButton onClick={() => handleSend(coupon?.id)}>
                  <Send />
                </IconButton>
              </div>
            ))}
          </>
        )}
        {active === 2 && (
          <>
            <div className='tabSubsActive mt-4'>Generate promo code</div>
            <Grid container sx={{ mt: 2 }}>
              {/* {adminSubscriptions?.map((plan, index) => (
                <div key={index} className='d-flex align-items-center'>
                  <Radio
                    checked={createType === plan?.nickname}
                    onChange={() => {
                      handleChange('createType', plan?.nickname)
                      handleChange('selectedPlan', plan)
                    }}
                  />
                  <div className='ml-1 mr-2'>{plan?.nickname}</div>
                </div>
              ))} */}
              <div class='percent'>
                <span class='form-element'>
                  <input
                    placeholder='% Off'
                    value={amountCoupon}
                    onChange={value =>
                      handleChange('amountCoupon', value.target.value)
                    }
                  />
                </span>
              </div>
              <div class='percent'>
                <span class='form-element'>
                  <input
                    placeholder='Name'
                    style={{ width: 300 }}
                    value={nameCoupon}
                    onChange={value =>
                      handleChange('nameCoupon', value.target.value)
                    }
                  />
                </span>
              </div>
              <div class='percent'>
                <span class='form-element'>
                  <input
                    placeholder='Duration'
                    value={durationCoupon}
                    onChange={value =>
                      handleChange('durationCoupon', value.target.value)
                    }
                    // onChange={value => filtered(value.target.value)}
                  />
                </span>
              </div>
              <div className='d-flex align-items-center c-pointer'>
                <Button
                  variant='contained'
                  onClick={() => handleSend('')}
                  disabled={!amountCoupon || !nameCoupon || !durationCoupon}
                  sx={{
                    height: 30,
                    textTransform: 'capitalize',
                    backgroundColor: COLORS.secondary,
                    color: COLORS.white,
                    borderRadius: 30,
                    marginRight: 1,
                    width: 150
                  }}
                >
                  Generate
                </Button>
              </div>
            </Grid>
          </>
        )}
        {/* {active === 3 && (
          <AppTable
            rows={filteredList}
            // flagAction={_updateUser}
            // deleteAction={deleteAction}
            // banAction={banAction}
            // _reportUser={_reportUser}
            // approval
            rowsPage={5}
            // headingLeft={'User activity'}
            headingRight={''}
            headCells={headCells1}
          />
        )} */}
      </Container>
      <Dialog
        PaperProps={{ classes: { root: 'paper' } }}
        onClose={() => handleChange('feedbackData', null)}
        open={feedbackData !== null}
      >
        <div className={'zipModal'}>
          <div className='d-flex justify-content-center'>
            <img
              src={feedbackData?.user?.picture}
              className='feedbackModalImage'
            />
          </div>
          <div className='d-flex justify-content-end'>
            <IconButton onClick={() => handleChange('feedbackData', null)}>
              <Cross />
            </IconButton>
          </div>
          <div className='d-flex align-items-center'>
            <p className='modalTextName'>Name</p>
            <p className='modalTextDes'>{feedbackData?.user?.name}</p>
          </div>
          <div className='d-flex align-items-center'>
            <p className='modalTextName'>Email</p>
            <p className='modalTextDes'>{feedbackData?.user?.email}</p>
          </div>
          <div className='d-flex align-items-center'>
            <p className='modalTextName'>Subject</p>
            <p className='modalTextDes'>{feedbackData?.title}</p>
          </div>
          <p className='modalTextName1'>Feedback message</p>
          <p className='modalTextDes'>{feedbackData?.message}</p>

          <p className='modalTextName1 mb-0'>Reply</p>
          <TextField
            margin='normal'
            required
            fullWidth
            id='email'
            multiline
            onChange={value => handleChange('reply', value.target.value)}
            name='email'
            variant='standard'
            autoComplete='email'
            inputProps={{
              className: 'textfieldMulti'
            }}
            InputProps={{
              disableUnderline: true
            }}
            className='textfieldContainer mb-5'
            autoFocus
          />
          <div className='d-flex justify-content-center mt-5'>
            <Button
              variant='contained'
              sx={{
                height: 40,
                textTransform: 'capitalize',
                backgroundColor: COLORS.secondary,
                borderRadius: 30,
                marginRight: 1,
                width: 70
              }}
              onClick={handleSubmit}
            >
              {loadingSend ? <CircularProgress className='loading' /> : 'Send'}
            </Button>
          </div>
        </div>
      </Dialog>
    </Layout>
  )
}

export default function Subscription () {
  return <SubscriptionContent />
}
