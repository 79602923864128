import * as React from 'react'
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Toolbar,
  Typography,
  IconButton,
  Button,
  Switch,
  Checkbox,
  CircularProgress
} from '@mui/material'
import { alpha } from '@mui/material/styles'
import DeleteIcon from '../../assets/svg/delete.svg'
import { ReactComponent as DeleteUser } from '../../assets/svg/deleteUser.svg'
import { ReactComponent as BlockUser } from '../../assets/svg/block.svg'
import { ReactComponent as PdfIcon } from '../../assets/svg/pdf.svg'
import WarningIcon from '../../assets/svg/warninggrey.svg'
import WarningRedIcon from '../../assets/svg/warningRed.svg'
import { COLORS } from '../../constants'
import moment from 'moment/moment'

function EnhancedTableHead(props) {
  const { headCells, feedback } = props

  return (
    <TableHead>
      <TableRow>
        {!feedback && <TableCell padding='checkbox'></TableCell>}
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={false}
            sx={{
              color: COLORS.secondary,
              fontSize: 20,
              fontWeight: 'bold',
              textTransform: 'capitalize'
            }}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

const EnhancedTableToolbar = props => {
  const { numSelected, headingRight, headingLeft, goto } = props

  return (
    <Toolbar
      className='header'
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: theme =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            )
        })
      }}
    >
      <Typography
        sx={{ flex: '1 1 100%' }}
        variant='h6'
        id='tableTitle'
        component='div'
      >
        {headingLeft}
      </Typography>
      <Typography
        onClick={goto}
        sx={{ flex: '1 1 20%', color: '#0AA0F4', cursor: 'pointer' }}
        variant='h6'
        id='tableTitle'
        component='div'
      >
        {headingRight}
      </Typography>
    </Toolbar>
  )
}

export default function AppTable({
  rows,
  headCells,
  headingLeft,
  nowarning,
  headingRight,
  toggle,
  rowsPage,
  feedback,
  goto,
  banAction,
  deleteAction,
  flagAction,
  onClickItem,
  _reportUser,
  handleChange,
  flagged,
  ban_from,
  ban_date,
  loadingBan,
  handleBan,
  handleUnban,
  loadingUnban,
  isProduct,
  isMilk,
  noPagination,
  handleDelete
}) {
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(rowsPage || 5)

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  console.log('rows', rows)

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows?.length) : 0

  return (
    <Paper
      sx={{
        width: '100%',
        mb: 2,
        borderRadius: 4,
        boxShadow: 'none',
        backgroundColor: 'transparent'
      }}
    >
      <EnhancedTableToolbar
        headingLeft={headingLeft}
        goto={goto}
        headingRight={headingRight}
      />
      <TableContainer>
        <Table
          sx={{ minWidth: 750 }}
          aria-labelledby='tableTitle'
          size={'medium'}
        >
          <EnhancedTableHead
            headCells={headCells}
            goto={goto}
            feedback={feedback}
          />
          <TableBody>
            {(rowsPerPage > 0
              ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : rows
            ).map((row, index) => {
              if (feedback) {
                return (
                  <TableRow hover key={index}>
                    <TableCell align='left'>
                      <img
                        src={
                          row?.user?.customer?.photo || row?.user?.driver?.photo
                        }
                        className='feedbackUserImage'
                      />
                    </TableCell>
                    <TableCell align='left'>{row?.user?.name}</TableCell>
                    <TableCell align='left'>{row?.user?.last_name}</TableCell>
                    <TableCell component='th' scope='row' padding='none'>
                      {row?.content}
                    </TableCell>
                    <TableCell align='left'>{row?.user?.type}</TableCell>
                    <TableCell align='left'> {row?.user?.email}</TableCell>
                    <TableCell align='left'>
                      {row?.user?.driver
                        ? '$' + row?.user?.driver?.earnings
                        : ''}
                    </TableCell>
                    <TableCell align='left'> {0}</TableCell>

                    <TableCell align='right'>
                      <Grid>
                        <IconButton onClick={() => deleteAction(row?.id)}>
                          <img src={DeleteIcon} />
                        </IconButton>
                        {!nowarning && (
                          <IconButton
                            onClick={() => flagAction(row?.id, !row?.flag)}
                          >
                            <img
                              src={row?.flag ? WarningRedIcon : WarningIcon}
                            />
                          </IconButton>
                        )}
                      </Grid>
                    </TableCell>
                  </TableRow>
                )
              } else {
                return (
                  <TableRow
                    hover
                    key={index}
                    className={onClickItem ? 'c-pointer' : ''}
                  // onClick={() => onClickItem(row?.id)}
                  >
                    <TableCell padding='checkbox'></TableCell>
                    {headCells?.map((heading, index) => {
                      if (heading?.label === 'status') {
                        return (
                          <TableCell
                            key={index}
                            component='th'
                            scope='row'
                            padding='none'
                          >
                            {isProduct || isMilk ? (
                              <Button
                                variant='contained'
                                sx={{
                                  height: 30,
                                  mt: 2,
                                  mb: 2,
                                  textTransform: 'capitalize',
                                  backgroundColor:
                                    row?.status === 'Active'
                                      ? COLORS.primary
                                      : COLORS.greyButton,
                                  borderRadius: 30,
                                  width: 50,
                                  marginRight: 1
                                }}
                              >
                                {row?.status}
                              </Button>
                            ) : (
                              <div className='d-flex align-items-center'>
                                <Button
                                  variant='contained'
                                  sx={{
                                    height: 30,
                                    textTransform: 'capitalize',
                                    backgroundColor:
                                      row?.type === 'Both' ||
                                        row?.type === 'Buyer'
                                        ? COLORS.secondary
                                        : COLORS.greyButton,
                                    borderRadius: 30,
                                    width: 50,
                                    marginRight: 1
                                  }}
                                >
                                  Buyer
                                </Button>
                                <Button
                                  variant='contained'
                                  sx={{
                                    height: 30,
                                    textTransform: 'capitalize',
                                    backgroundColor:
                                      row?.type === 'Both' ||
                                        row?.type === 'Seller'
                                        ? COLORS.secondary
                                        : COLORS.greyButton,
                                    borderRadius: 30,
                                    marginRight: 1,
                                    width: 50
                                  }}
                                >
                                  Seller
                                </Button>
                                <IconButton
                                  onClick={() => deleteAction(row?.id)}
                                >
                                  <DeleteUser />
                                </IconButton>
                                <IconButton onClick={() => banAction(row?.id)}>
                                  <BlockUser />
                                </IconButton>
                                {/* <IconButton onClick={() => _reportUser(row?.id)}>
                                <PdfIcon />
                              </IconButton> */}
                              </div>
                            )}
                          </TableCell>
                        )
                      } else {
                        return (
                          <TableCell
                            key={index}
                            component='th'
                            scope='row'
                            padding='none'
                            sx={{ color: '#3A596E', fontWeight: 'bold' }}
                          >
                            {heading?.id === 'name' && !isMilk && !isProduct && (
                              <img
                                src={row?.picture}
                                className='feedbackUserImage'
                              />
                            )}
                            {isProduct &&
                              heading?.id === 'name' &&
                              row?.user?.name}
                            {isProduct &&
                              heading?.id === 'email' &&
                              row?.user?.email}
                            {isMilk &&
                              heading?.id === 'name' &&
                              row?.user?.name}
                            {isMilk &&
                              heading?.id === 'email' &&
                              row?.user?.email}
                            {isProduct && heading?.id === 'title' && row?.title}
                            {isProduct &&
                              heading?.id === 'price' &&
                              '$ ' + row?.price}
                            {!flagged && !isProduct && row[heading?.id]}
                            {flagged && heading?.id === 'username' && (
                              <div>
                                {row?.reportee?.name}
                                <div className='text_primary mb-2 mt-3'>
                                  Action
                                </div>
                                <div className='mb-3'>
                                  <Button
                                    onClick={() => {
                                      handleChange('reportID', row?.id)
                                    }}
                                    variant='contained'
                                    sx={{
                                      height: 30,
                                      textTransform: 'capitalize',
                                      backgroundColor: COLORS.secondary,
                                      borderRadius: 30,
                                      marginRight: 1,
                                      width: 150
                                    }}
                                  >
                                    Warning Message
                                  </Button>
                                </div>
                              </div>
                            )}
                            {flagged && heading?.id === 'content' && (
                              <div>
                                {row?.reason}
                                <div className='text_primary mb-2 mt-4'></div>
                                <div className='mb-3'>
                                  <Button
                                    variant='contained'
                                    onClick={() => {
                                      handleChange(
                                        'product_id',
                                        row?.product?.id
                                      )
                                    }}
                                    sx={{
                                      height: 30,
                                      mt: 3,
                                      textTransform: 'capitalize',
                                      backgroundColor: COLORS.secondary,
                                      borderRadius: 30,
                                      marginRight: 1,
                                      width: 150
                                    }}
                                  >
                                    Delete content
                                  </Button>
                                </div>
                              </div>
                            )}
                            {flagged && heading?.id === 'ContentUser' && (
                              <div>
                                {row?.reportee?.name}
                                <div className='text_primary mb-2 mt-4'></div>
                                <div
                                  style={{ fontSize: 20 }}
                                  className='mb-3 text_primary mt-5'
                                >
                                  Ban user
                                </div>
                              </div>
                            )}
                            {flagged && heading?.id === 'num_of_reports' && (
                              <div>
                                {row?.num_of_reports}
                                <div className='text_primary mb-2 mt-4'>
                                  Ban From
                                </div>
                                <div
                                  style={{ fontSize: 14 }}
                                  className='mb-3 d-flex align-items-center'
                                >
                                  <Checkbox
                                    className='redRound'
                                    onClick={() => {
                                      handleChange(
                                        'ban_from',
                                        ban_from === row?.id + 'post'
                                          ? ''
                                          : row?.id + 'post'
                                      )
                                    }}
                                    checked={ban_from === row?.id + 'post'}
                                  />
                                  Posting
                                  {/* <div className='greenRound' /> */}
                                  <Checkbox
                                    // className='greenRound'
                                    color='success'
                                    onClick={() => {
                                      handleChange(
                                        'ban_from',
                                        ban_from === row?.id + 'app'
                                          ? ''
                                          : row?.id + 'app'
                                      )
                                    }}
                                    checked={ban_from === row?.id + 'app'}
                                  />
                                  From the app
                                </div>
                              </div>
                            )}
                            {flagged && heading?.id === 'reportedBy' && (
                              <div>
                                {row?.user?.name}
                                <div className='text_primary mb-2 mt-5'></div>
                                <div
                                  style={{ fontSize: 14 }}
                                  className='mb-3 d-flex align-items-center'
                                >
                                  <Checkbox
                                    // className='greenRound'
                                    color='success'
                                    onClick={() => {
                                      handleChange(
                                        'ban_from',
                                        ban_from === row?.id + 'both'
                                          ? ''
                                          : row?.id + 'both'
                                      )
                                    }}
                                    checked={ban_from === row?.id + 'both'}
                                  />
                                  <div>
                                    <div>Period of time</div>
                                    {ban_from === row?.id + 'both' && (
                                      <>
                                        <input
                                          type={'date'}
                                          min={new Date()}
                                          onChange={val =>
                                            handleChange(
                                              'ban_date',
                                              val.target.value
                                            )
                                          }
                                        />
                                      </>
                                    )}
                                  </div>
                                </div>
                              </div>
                            )}
                            {flagged && heading?.id === 'comment' && (
                              <div>
                                {row?.reason}
                                <div className='text_primary mb-2 mt-5'></div>
                                <div
                                  style={{ fontSize: 14 }}
                                  className='mb-3 d-flex align-items-center'
                                >
                                  <Button
                                    variant='contained'
                                    onClick={() => handleBan(row?.user?.id)}
                                    sx={{
                                      height: 30,
                                      textTransform: 'capitalize',
                                      backgroundColor: COLORS.secondary,
                                      borderRadius: 30,
                                      marginRight: 1,
                                      width: 70
                                    }}
                                  >
                                    {loadingBan ? (
                                      <CircularProgress className='loading' />
                                    ) : (
                                      'Ban'
                                    )}
                                  </Button>
                                  <Button
                                    variant='contained'
                                    onClick={() => handleUnban(row?.user?.id)}
                                    sx={{
                                      height: 30,
                                      textTransform: 'capitalize',
                                      backgroundColor: COLORS.secondary,
                                      borderRadius: 30,
                                      marginRight: 1,
                                      width: 100
                                    }}
                                  >
                                    {loadingUnban ? (
                                      <CircularProgress className='loading' />
                                    ) : (
                                      'Removed'
                                    )}
                                  </Button>
                                </div>
                              </div>
                            )}
                            {
                              heading?.id === 'deletePost' &&
                              <Button
                                variant='contained'
                                onClick={() => handleDelete(row?.id)}
                                sx={{
                                  height: 30,
                                  textTransform: 'capitalize',
                                  backgroundColor: COLORS.secondary,
                                  borderRadius: 30,
                                  marginRight: 1,
                                  width: 70
                                }}
                              >
                                Delete
                              </Button>
                            }
                          </TableCell>
                        )
                      }
                    })}
                  </TableRow>
                )
              }
            })}
            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: 53 * emptyRows
                }}
              >
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {!noPagination && (
        <div className='pagination'>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component='div'
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      )}
    </Paper>
  )
}
