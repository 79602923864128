import * as React from 'react'
import Box from '@mui/material/Box'
import List from '@mui/material/List'
import drops from '../../assets/svg/drops.svg'
import { ReactComponent as Analytics } from '../../assets/svg/analytics.svg'
import { ReactComponent as Users } from '../../assets/svg/users.svg'
import { ReactComponent as Subscriptions } from '../../assets/svg/subscriptions.svg'
import { ReactComponent as Feedback } from '../../assets/svg/feedback.svg'
import { ReactComponent as Logout } from '../../assets/svg/logout.svg'
import { ReactComponent as FlaggedContent } from '../../assets/svg/flaggedcontent.svg'
import ListItemButton from '@mui/material/ListItemButton'
import LOGO from '../../assets/svg/logo.svg'
import { useLocation, useNavigate } from 'react-router-dom'
import AppContext from '../../Context'
import { useContext } from 'react'
import { useEffect } from 'react'

function LayoutContent ({ children }) {
  const navigate = useNavigate()
  const location = useLocation()
  const {
    user,
    setUser,
    _getAnalytics,
    _getAllUsers,
    _getZipcodes,
    _getCoupons,
    _getFeedbacks,
    _getReports,
    _getAdminSubscriptions,
    _getDashboard
  } = useContext(AppContext)
  const userData = localStorage.getItem('user')
  useEffect(() => {
    if (userData) {
      setUser(JSON.parse(userData))
      _getAnalytics()
      _getAllUsers()
      // _getZipcodes()
      _getCoupons()
      _getFeedbacks()
      _getDashboard()
      _getReports()
      _getAdminSubscriptions()
    }
  }, [])

  const handleListItemClick = (route, index) => {
    if (userData) {
      navigate(route)
    } else {
      alert('please login first')
    }
  }
  const logout = () => {
    localStorage.removeItem('token')
    localStorage.removeItem('user')
    navigate('/')
  }

  const list = [
    { title: 'Analytics', icon: <Analytics />, route: '/dashboard' },
    { title: 'Users', icon: <Users />, route: '/users' },
    {
      title: 'Subscriptions',
      icon: <Subscriptions />,
      route: '/subscriptions'
    },
    { title: 'Feedback', icon: <Feedback />, route: '/feedback' },
    {
      title: 'Flagged content',
      icon: <FlaggedContent />,
      route: '/flagged-content'
    }
  ]

  return (
    <Box sx={{ height: '100vh', backgroundColor: '#F5EDEE' }}>
      <div className='d-flex'>
        <img src={LOGO} style={{ width: 150, marginLeft: 20 }} />
        <List className='d-flex'>
          {list.map((item, index) => (
            <ListItemButton
              key={index}
              selected={location.pathname === item.route}
              onClick={() => handleListItemClick(item.route)}
              className={
                location.pathname === item.route
                  ? 'listButtonActive'
                  : 'listButton'
              }
            >
              <div className='listText'>{item.title}</div>
              {item.icon}
            </ListItemButton>
          ))}
        </List>
        {userData && (
          <div onClick={logout} className='mt-3 ml-4 c-pointer'>
            <Logout />
            <p className='text-center text_primary mt-1'>LOGOUT</p>
          </div>
        )}
      </div>
      <Box
        component='main'
        sx={{
          backgroundColor: '#F5EDEE',
          flexGrow: 1,
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          overflow: 'auto'
        }}
      >
        {children}
        <div className='drops'>
          <img src={drops} className='' />
        </div>
      </Box>
    </Box>
  )
}

export default function Layout ({ children }) {
  return <LayoutContent children={children} />
}
